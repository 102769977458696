<script setup>
import { useField } from "vee-validate";
import { useI18n } from "vue-i18n";
import { watch } from "vue";
import VField from "@/features/FormField/VField.vue";
import { useSort } from "@/features/Sort/useSort";

const TRANSLATION_KEY = "reports.information";
const { t } = useI18n();
const { loadSorts, loadObjects }
  = useLoadAutocompleteSelects();

const sortField = useField("sort");
const objectField = useField("object");
const { showObject } = useSort(sortField);

watch(showObject, val => !val && objectField.setValue(null));
const { hasMoreThenOneSort } = useSystemStore();
</script>

<template>
  <div class="report-form">
    <VField v-slot="{ field, errorMessage }" name="sort">
      <VModernSelect
        v-show="hasMoreThenOneSort"
        v-bind="field"
        :label-left="t(`${TRANSLATION_KEY}.sort_label`)"
        required
        :error-message="errorMessage"
        :on-load="loadSorts"
        :placeholder="t(`${TRANSLATION_KEY}.sort_placeholder`)"
        :no-results-text="t('base.empty')"
        :loading-text="t('base.loading')"
        :refresh-text="t('base.refresh')"
        has-new-style
        key-field-name="@id"
      />
    </VField>
    <VField v-if="showObject" v-slot="{ field, errorMessage }" name="object">
      <VModernSelect
        v-bind="field"
        :error-message="errorMessage"
        :label-left="t(`${TRANSLATION_KEY}.object_label`)"
        required
        :on-load="loadObjects"
        :placeholder="t(`${TRANSLATION_KEY}.object_placeholder`)"
        :no-results-text="t('base.empty')"
        :loading-text="t('base.loading')"
        :refresh-text="t('base.refresh')"
        has-new-style
        key-field-name="@id"
      />
    </VField>
    <VField v-slot="{ field, errorMessage }" name="description">
      <VTextArea
        v-bind="field"
        :error-message="errorMessage"
        required
        :label-left="t(`${TRANSLATION_KEY}.description_label`)"
        :placeholder="t(`${TRANSLATION_KEY}.description_placeholder`)"
        resize="is-vertical"
        multiline
        rows="6"
        has-new-style
        variant="is-white"
      />
    </VField>
  </div>
</template>

<style lang="scss" scoped>
.report-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
