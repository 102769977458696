<script setup lang="ts">
import { useField } from "vee-validate";
import { computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import ConnectIcon from "@/features/Auth/OAuth/Thirdparties/ConnectIcon.vue";
import VField from "@/features/FormField/VField.vue";
import { useSort } from "@/features/Sort/useSort";
import api from "@/core/api.js";

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
});

const TRANSLATION_KEY = "reports.information";
const { t } = useI18n();
const { loadObjects, loadSorts }
  = useLoadAutocompleteSelects();

const sortField = useField("sort");
const objectField = useField("object");
const { showObject } = useSort(sortField);

watch(showObject, val => !val && objectField.setValue(null));

const createdAt = computed(() => {
  return new Date(props.item.createdAt).toLocaleString("nl", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
});
const updatedAt = computed(() => {
  return new Date(props.item.updatedAt).toLocaleString("nl", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
});
const { hasMoreThenOneSort } = useSystemStore();

async function loadItem(modelValue: any) {
  if (typeof modelValue !== "string") {
    return null;
  }
  if (!modelValue.startsWith("/api/")) {
    return null;
  }

  return await api.get<object>(modelValue).then(r => r.data);
}
</script>

<template>
  <div class="edit-report-information-form">
    <div
      v-if="item.thirdPartyName || item.meta?.name || item.meta?.phone"
      class="meta"
    >
      <div v-if="item.thirdPartyName" class="block">
        <div class="title">
          {{ `${t("reports.createdVia")}: ` }}
        </div>
        <div class="value">
          <ConnectIcon non-absolute :third-party-name="item.thirdPartyName" />
          {{ t(`thirdParty.${item.thirdPartyName}`) }}
        </div>
      </div>
      <div v-if="item.meta?.name" class="block">
        <div class="title">
          {{ `${t("reports.guestName")}: ` }}
        </div>
        <div class="value">
          {{ item.meta.name }}
        </div>
      </div>
      <div v-if="item.meta?.phone" class="block">
        <div class="title">
          {{ `${t("reports.guestPhone")}: ` }}
        </div>
        <div class="value">
          <a :href="`tel:${item.meta.phone}`">{{ item.meta.phone }}</a>
        </div>
      </div>
    </div>
    <VField v-slot="{ field, errorMessage }" name="sort">
      <VModernSelect
        v-show="hasMoreThenOneSort"
        v-bind="field"
        :label-left="t(`${TRANSLATION_KEY}.sort_label`)"
        required
        :error-message="errorMessage"
        :on-load="loadSorts"
        :placeholder="t(`${TRANSLATION_KEY}.sort_placeholder`)"
        :no-results-text="t('base.empty')"
        :loading-text="t('base.loading')"
        :refresh-text="t('base.refresh')"
        has-new-style
        key-field-name="@id"
        :initial-item-provider="loadItem"
      />
    </VField>
    <VField v-if="showObject" v-slot="{ field, errorMessage }" name="object">
      <VModernSelect
        v-bind="field"
        :error-message="errorMessage"
        :label-left="t(`${TRANSLATION_KEY}.object_label`)"
        required
        :on-load="loadObjects"
        :placeholder="t(`${TRANSLATION_KEY}.object_placeholder`)"
        :no-results-text="t('base.empty')"
        :loading-text="t('base.loading')"
        :refresh-text="t('base.refresh')"
        has-new-style
        key-field-name="@id"
        :initial-item-provider="loadItem"
      />
    </VField>
    <VField v-slot="{ field, errorMessage }" name="description">
      <VTextArea
        v-bind="field"
        :error-message="errorMessage"
        required
        :label-left="t(`${TRANSLATION_KEY}.description_label`)"
        :placeholder="t(`${TRANSLATION_KEY}.description_placeholder`)"
        resize="is-vertical"
        multiline
        rows="6"
        has-new-style
        variant="is-white"
      />
    </VField>
    <div class="meta">
      <div class="meta-item">
        <div class="title">
          {{ `${t("reports.createdAt")}: ` }}
        </div>
        <div class="text">
          {{ createdAt }}
        </div>
      </div>
      <div class="meta-item">
        <div class="title">
          {{ `${t("reports.updatedAt")}: ` }}
        </div>
        <div class="text">
          {{ updatedAt }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.edit-report-information-form {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .picker {
    display: flex;
    gap: 10px;
  }
  .block {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    background-color: var(--background);
    border-radius: 5px;
    color: var(--text);
    font-size: 14px;

    &.status,
    &.is-flex-col.status {
      align-items: center;
      justify-content: start;
      height: 200px;
      color: var(--text);

      .status-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        margin-top: 25px;

        .icon {
          font-size: 50px;

          &.is-svg {
            height: 75px;

            &.golfcart {
              margin-left: -20px; // because of the wind
            }
          }
        }

        .text {
          text-align: center;
          font-weight: bold;
          font-size: 14px;
        }
      }
    }

    &.is-inversed {
      background-color: var(--background-modal);
      border: 2px solid var(--background);

      .title {
        color: var(--primary);
      }
    }

    &.is-flex-col {
      align-items: start;
      justify-content: center;
      flex-direction: column;

      .value {
        margin-top: 10px;
        margin-left: initial;
      }
    }

    .value {
      display: flex;
      gap: 5px;
      margin-left: auto;
      align-items: center;

      &:not(.is-not-bold) {
        font-weight: 500;
      }
    }
  }
}
.meta {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .meta-item {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    gap: 5px;
    color: var(--text);

    .title {
      font-weight: bold;
    }

    .text {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}
.icon_holder {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
</style>
