<script setup lang="ts">
import { ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { UserRole } from "@verbleif/shared";
import { TaskStatus } from "@verbleif/lib";
import {
  useStatus,
} from "./useStatus";
import { useRightsStore } from "@/core/store/RightStore";

const props = withDefaults(defineProps<{
  modelValue?: TaskStatus
  loading: boolean
  singletonGroup?: string
}>(), {
  modelValue: undefined,
  singletonGroup: undefined,
});

const emit = defineEmits(["update:modelValue"]);

const { t } = useI18n();
const statusClass = ref("");
const { hasRole } = useRightsStore();
const { statusToText, statusToClass } = useStatus();

const statuses = [TaskStatus.IN_PROGRESS, TaskStatus.OPEN, TaskStatus.WAITING_FOR, TaskStatus.FINISHED];

if (hasRole(UserRole.ROLE_DEPARTMENT_MANAGER)) {
  statuses.push(TaskStatus.CHECKED);
}

function updateStatus(v: TaskStatus, doClose: any) {
  emit("update:modelValue", v);
  doClose();
}

watchEffect(() => {
  if (props.loading || !props.modelValue) {
    statusClass.value = "";
    return;
  }
  statusClass.value = statusToClass(props.modelValue);
});
</script>

<template>
  <div class="status-container">
    <VPopper
      trigger="clickToToggle"
      append-to-body
      :options="{ placement: 'bottom-start' }"
      transition="fade"
      :singleton-group="singletonGroup"
      :disabled="loading"
      stop-propagation
    >
      <template #reference>
        <slot>
          <div
            class="indicator-wrapper"
            :class="[statusClass, { 'is-loading': loading }]"
          >
            <VSkeleton
              v-if="loading || !modelValue"
              :width="10"
              :height="10"
              width-unit="px"
              :dynamic-width="false"
              :pill="false"
            />
            <template v-else>
              <VTooltip :content="t(statusToText(modelValue))" placement="top">
                <template #reference>
                  <div class="indicator" :class="statusClass" />
                </template>
              </VTooltip>
            </template>
          </div>
        </slot>
      </template>
      <template #default="{ doClose }">
        <div class="status-change">
          <div
            v-for="s in statuses"
            :key="s"
            class="status"
            :class="[
              { 'is-active': statusClass === statusToClass(s) },
              statusToClass(s),
            ]"
            @click="() => updateStatus(s, doClose)"
          >
            {{ t(statusToText(s)) }}
            <FontAwesomeIcon class="icon" :icon="['far', 'circle-check']" />
          </div>
        </div>
      </template>
    </VPopper>
  </div>
</template>

<style lang="scss" scoped>
.status-change {
  border-radius: 8px;
  min-width: 170px;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  box-shadow: 0 1px 10px var(--popper-shadow);
  background: var(--background-modal);

  .status {
    user-select: none;
    cursor: pointer;
    color: var(--input-placeholder);
    font-size: 14px;
    border-radius: 4px;
    padding: 8px 8px 8px 26px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    position: relative;

    .icon {
      display: none;
      color: var(--input-placeholder);
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      left: 8px;
      border-radius: 2px;
    }

    &.is-active {
      font-weight: bold;
      color: var(--text);

      .icon {
        display: initial;
        margin-left: auto;
      }
    }

    &:hover {
      color: white;
    }

    &.is-open {
      &:hover {
        background-color: #32c5ff;
      }

      &::before {
        background-color: #32c5ff;
      }
    }

    &.is-in_progress {
      &:hover {
        background-color: #979797;
      }

      &::before {
        background-color: #979797;
      }
    }

    &.is-waiting {
      &:hover {
        background-color: #ff003c;
      }

      &::before {
        background-color: #ff003c;
      }
    }

    &.is-done {
      &:hover {
        background-color: var(--primary);
      }

      &::before {
        background-color: var(--primary);
      }
    }

    &.is-completed {
      &:hover {
        background-color: var(--primary);
      }

      &::before {
        background-color: var(--primary);
      }
    }
  }
}

.status-container {
  cursor: pointer;

  .indicator-wrapper {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    transition: ease all 250ms;

    &:not(.is-loading):hover {
      border: 1px solid;
    }

    &.is-open {
      border-color: #32c5ff;
    }

    &.is-in_progress {
      border-color: #979797;
    }

    &.is-waiting {
      border-color: #ff003c;
    }

    &.is-done {
      border-color: var(--primary);
    }

    &.is-completed {
      border-color: var(--primary);
    }

    .indicator {
      width: 10px;
      height: 10px;
      border-radius: 1px;

      &.is-open {
        background-color: #32c5ff;
      }

      &.is-in_progress {
        background-color: #979797;
      }

      &.is-waiting {
        background-color: #ff003c;
      }

      &.is-done {
        background-color: var(--primary);
      }

      &.is-completed {
        background-color: var(--primary);
      }
    }
  }
}
</style>
