<script setup>
import { useField, useSetFieldValue } from "vee-validate";
import { useI18n } from "vue-i18n";
import { provide, watch } from "vue";
import { Feature, UserRole } from "@verbleif/shared";
import { useRightsStore } from "@/core/store/RightStore";
import DeadlineInput from "@/features/Deadline/DeadlineInput.vue";
import BlockingItem from "@/features/Blocking/BlockingItem.vue";
import VField from "@/features/FormField/VField.vue";
import { DeadlineSymbol } from "@/features/Deadline/DeadlineSymbol";
import { useSort } from "@/features/Sort/useSort";
import { useObject } from "@/features/ObjectSpaceMean/useObject";

defineProps({
  triggerAssignTo: {
    type: Function,
    default: () => {},
  },
});

const TRANSLATION_KEY = "tasks.information";
const { t } = useI18n();
const { hasFeature, hasRole } = useRightsStore();
const { loadSorts, loadObjects }
  = useLoadAutocompleteSelects();

const sortField = useField("sort");
const objectField = useField("object");
const isAssignedToDirty = useIsFieldDirty("assignTo");

const { hasObject } = useObject(objectField);
const { showObject } = useSort(sortField);

watch(showObject, val => !val && objectField.setValue(null));

const setAssignTo = useSetFieldValue("assignTo");

watch(() => sortField?.value?.value, () => {
  if (!isAssignedToDirty.value) {
    if (sortField?.value?.value?.department?.["@id"]) {
      setAssignTo([sortField?.value?.value.department["@id"]]);
    }
  }
});
const { value: timeSensitive } = useField("timeSensitive");

function toggleTimeSensitive() {
  timeSensitive.value = !timeSensitive.value;
}

provide(DeadlineSymbol, {
  timeSensitive,
  toggleTimeSensitive,
});
const { hasMoreThenOneSort } = useSystemStore();
</script>

<template>
  <div class="task-form">
    <VField v-slot="{ field, errorMessage }" name="sort">
      <VModernSelect
        v-show="hasMoreThenOneSort"
        v-bind="field"
        :label-left="t(`${TRANSLATION_KEY}.sort_label`)"
        required
        :error-message="errorMessage"
        :on-load="loadSorts"
        :placeholder="t(`${TRANSLATION_KEY}.sort_placeholder`)"
        :no-results-text="t('base.empty')"
        :loading-text="t('base.loading')"
        :refresh-text="t('base.refresh')"
        key-field-name="@id"
        has-new-style
      />
    </VField>
    <VField v-if="showObject" v-slot="{ field, errorMessage }" name="object">
      <VModernSelect
        v-bind="field"
        :error-message="errorMessage"
        :label-left="t(`${TRANSLATION_KEY}.object_label`)"
        required
        :on-load="loadObjects"
        :placeholder="t(`${TRANSLATION_KEY}.object_placeholder`)"
        :no-results-text="t('base.empty')"
        :loading-text="t('base.loading')"
        :refresh-text="t('base.refresh')"
        key-field-name="@id"
        has-new-style
      />
    </VField>
    <VField
      v-if="hasObject"
      v-slot="{ field, errorMessage }"
      name="blocking"
    >
      <VSwitch
        v-bind="field"
        has-new-style
        :error-message="errorMessage"
        :label-left="t(`${TRANSLATION_KEY}.blocking_label`)"
        icon-left
        required
      >
        <template #iconLeft>
          <BlockingItem :loading="false" :blocking="field.modelValue" />
        </template>
      </VSwitch>
    </VField>
    <VField
      v-if="!hasFeature(Feature.HIDE_QUANTITY)"
      v-slot="{ field, errorMessage }"
      name="quantity"
    >
      <VInput
        v-bind="field"
        :error-message="errorMessage"
        :label-left="t(`${TRANSLATION_KEY}.quantity_label`)"
        :placeholder="t(`${TRANSLATION_KEY}.quantity_placeholder`)"
        type="number"
        min="1"
        has-new-style
      />
    </VField>
    <VField v-slot="{ field, errorMessage }" name="deadlineAt">
      <DeadlineInput
        :time-sensitive="!!timeSensitive"
        v-bind="field"
        :error-message="errorMessage"
        :label-left="t(`${TRANSLATION_KEY}.deadline_label`)"
      />
    </VField>
    <VField v-slot="{ field, errorMessage }" name="description">
      <VTextArea
        v-bind="field"
        :error-message="errorMessage"
        required
        :label-left="t(`${TRANSLATION_KEY}.description_label`)"
        :placeholder="t(`${TRANSLATION_KEY}.description_placeholder`)"
        resize="is-vertical"
        multiline
        rows="6"
        has-new-style
        variant="is-white"
      />
    </VField>
    <VField
      v-if="hasRole(UserRole.ROLE_ADMIN)"
      v-slot="{ field, errorMessage }"
      name="isTemplate"
    >
      <VSwitch
        v-bind="field"
        has-new-style
        :error-message="errorMessage"
        :label-left="t(`${TRANSLATION_KEY}.is_template_label`)"
        icon-left
        required
      />
    </VField>
  </div>
</template>

<style lang="scss" scoped>
.task-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.task-template-btn {
  display: flex;
}
</style>
