<script setup lang="ts">
import { useField } from "vee-validate";
import { useI18n } from "vue-i18n";
import { provide, watch } from "vue";
import { Feature, UserRole, useAuthStore } from "@verbleif/shared";
import DeadlineInput from "@/features/Deadline/DeadlineInput.vue";
import BlockingItem from "@/features/Blocking/BlockingItem.vue";
import VField from "@/features/FormField/VField.vue";
import { DeadlineSymbol } from "@/features/Deadline/DeadlineSymbol";
import { useObject } from "@/features/ObjectSpaceMean/useObject";
import { useSort } from "@/features/Sort/useSort";
import { useRightsStore } from "@/core/store/RightStore";
import api from "@/core/api.js";

defineProps({
  item: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const { hasFeature } = useRightsStore();
const TRANSLATION_KEY = "tasks.information";
const { t } = useI18n();
const { loadObjects, loadSorts }
  = useLoadAutocompleteSelects();
const { hasRole } = useRightsStore();
const authStore = useAuthStore();
const sortField = useField("sort");
const objectField = useField("object");
const { hasObject } = useObject(objectField);
const { showObject } = useSort(sortField);

watch(showObject, val => !val && objectField.setValue(null));

const { value: timeSensitive } = useField("timeSensitive");

function toggleTimeSensitive() {
  timeSensitive.value = !timeSensitive.value;
}

async function loadItem(modelValue: any) {
  if (typeof modelValue !== "string") {
    return null;
  }
  if (!modelValue.startsWith("/api/")) {
    return null;
  }

  return await api.get<object>(modelValue).then(r => r.data);
}

provide(DeadlineSymbol, {
  timeSensitive,
  toggleTimeSensitive,
});
const { hasMoreThenOneSort } = useSystemStore();
</script>

<template>
  <div class="task-form">
    <VField v-slot="{ field, errorMessage }" name="sort">
      <VModernSelect
        v-show="hasMoreThenOneSort"
        v-bind="field"
        :disabled="
          !hasRole(UserRole.ROLE_DEPARTMENT_MANAGER) && item.createdBy?.id !== authStore.user.value?.id
        "
        :label-left="t(`${TRANSLATION_KEY}.sort_label`)"
        required
        :error-message="errorMessage"
        :on-load="loadSorts"
        :placeholder="t(`${TRANSLATION_KEY}.sort_placeholder`)"
        :no-results-text="t('base.empty')"
        :loading-text="t('base.loading')"
        :refresh-text="t('base.refresh')"
        has-new-style
        key-field-name="@id"
        :initial-item-provider="loadItem"
      />
    </VField>
    <VField v-if="showObject" v-slot="{ field, errorMessage }" name="object">
      <VModernSelect
        v-bind="field"
        :error-message="errorMessage"
        :label-left="t(`${TRANSLATION_KEY}.object_label`)"
        required
        :on-load="loadObjects"
        :placeholder="t(`${TRANSLATION_KEY}.object_placeholder`)"
        :no-results-text="t('base.empty')"
        :loading-text="t('base.loading')"
        :refresh-text="t('base.refresh')"
        :disabled="
          !hasRole(UserRole.ROLE_DEPARTMENT_MANAGER) && item.createdBy !== authStore.user.value?.['@id']
        "
        has-new-style
        key-field-name="@id"
        :initial-item-provider="loadItem"
      />
    </VField>
    <VField
      v-if="hasObject"
      v-slot="{ field, errorMessage }"
      name="blocking"
    >
      <VSwitch
        v-bind="field"
        has-new-style
        :error-message="errorMessage"
        :label-left="t(`${TRANSLATION_KEY}.blocking_label`)"
        icon-left
        required
      >
        <template #iconLeft>
          <BlockingItem :loading="false" :blocking="!!field.modelValue" />
        </template>
      </VSwitch>
    </VField>
    <VField
      v-if="!hasFeature(Feature.HIDE_QUANTITY)"
      v-slot="{ field, errorMessage }"
      name="quantity"
    >
      <VInput
        v-bind="field"
        :error-message="errorMessage"
        :label-left="t(`${TRANSLATION_KEY}.quantity_label`)"
        :placeholder="t(`${TRANSLATION_KEY}.quantity_placeholder`)"
        type="number"
        min="1"
        :disabled="
          !hasRole(UserRole.ROLE_DEPARTMENT_MANAGER) && item.createdBy?.id !== authStore.user.value?.id
        "
        has-new-style
      />
    </VField>
    <VField v-slot="{ field, errorMessage }" name="deadlineAt">
      <DeadlineInput
        :time-sensitive="!!timeSensitive"
        v-bind="field"
        :initial-value="null"
        :error-message="errorMessage"
        :label-left="t(`${TRANSLATION_KEY}.deadline_label`)"
        :disabled="
          !hasRole(UserRole.ROLE_DEPARTMENT_MANAGER) && item.createdBy?.id !== authStore.user.value?.id
        "
      />
    </VField>
    <VField v-slot="{ field, errorMessage }" name="description">
      <VTextArea
        v-bind="field"
        :error-message="errorMessage"
        required
        :label-left="t(`${TRANSLATION_KEY}.description_label`)"
        :placeholder="t(`${TRANSLATION_KEY}.description_placeholder`)"
        resize="is-vertical"
        :disabled="
          !hasRole(UserRole.ROLE_DEPARTMENT_MANAGER) && item.createdBy?.id !== authStore.user.value?.id
        "
        multiline
        rows="6"
        has-new-style
        variant="is-white"
      />
    </VField>
  </div>
</template>

<style lang="scss" scoped>
.task-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
