import type { RouteRecordRaw } from "vue-router";

export const AuthRoutes = <RouteRecordRaw[]>[
  {
    name: "login-root",
    path: "/",
    component: () => import("./AuthContainer.vue"),
    children: [
      {
        path: "",
        name: "login-root",
        redirect: { name: "login" },
      },
      {
        path: "/:catchAll(.*)",
        redirect: { name: "login" },
      },
      {
        path: "login",
        name: "login",
        component: () => import("./Login/LoginView.vue"),
        meta: {
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "choose-location",
        name: "choose-location",
        component: () => import("./Location/ChooseLocation.vue"),
        meta: {
          hideNavbar: true,
        },
      },
      {
        path: "forgot-password",
        name: "forgot-password",
        component: () => import("./ForgotPassword/ForgotPassword.vue"),
        meta: {
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "forgot-password/success",
        name: "forgot-password-success",
        component: () => import("./ForgotPassword/ForgotPasswordSuccess.vue"),
        meta: {
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "reset-password/:token",
        name: "reset-password",
        component: () => import("./ResetPassword/ResetPassword.vue"),
        meta: {
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "reset-password/success",
        name: "reset-password-success",
        component: () => import("./ResetPassword/ResetPasswordSuccess.vue"),
        meta: {
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "reset-password/failed",
        name: "reset-password-failed",
        component: () => import("./ResetPassword/ResetPasswordFailed.vue"),
        meta: {
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "impersonate/:clientId/:username",
        name: "impersonate",
        component: () => import("./Impersonate/ImpersonateView.vue"),
        meta: {
          skipAuthChecks: true,
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "oauth/callback",
        name: "oauth-callback",
        component: () => import("./OAuth/CallbackView.vue"),
        meta: {
          skipAuthChecks: true,
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "oauth/callback/failed",
        name: "oauth-callback-failed",
        component: () => import("./OAuth/CallbackFailed.vue"),
        meta: {
          skipAuthChecks: true,
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "oauth/callback/choose-administration",
        name: "oauth-callback-choose-administration",
        component: () => import("./OAuth/ChooseAdministration.vue"),
        meta: {
          skipAuthChecks: true,
          hideNavbar: true,
        },
      },
      {
        path: "oauth/callback/choose-administration/success",
        name: "oauth-callback-choose-administration-success",
        component: () => import("./OAuth/ChooseAdministrationSuccess.vue"),
        meta: {
          skipAuthChecks: true,
          hideNavbar: true,
        },
      },
      {
        path: "oauth/callback/choose-administration/failed",
        name: "oauth-callback-choose-administration-failed",
        component: () => import("./OAuth/ChooseAdministrationFailed.vue"),
        meta: {
          skipAuthChecks: true,
          hideNavbar: true,
        },
      },
    ],
  },
];

export default AuthRoutes;
